import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { FaCopy, FaPlus } from "react-icons/fa6";
import { toast } from "react-toastify";
import { httpRequest } from "../utils/httpRequest";
import { BASE_URL } from "../config";
import { CartContext } from "../context/CartContext";

import { CgCopy } from "react-icons/cg";
import { BiCopy } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { paymentRequest } from "../utils/payment";
import Loading from "./Loading";
import * as Yup from "yup";

const CheckoutForm = ({ packing, total }) => {
  const [states, setStates] = useState();
  const [cities, setCities] = useState();
  const [payinfo, setpayinfo] = useState();
  const [loading, setloading] = useState(false);
  const [checked, setchecked] = useState(false);

  const navigate = useNavigate();

  const { cart, setCart } = useContext(CartContext);

  async function getData() {
    const user = JSON.parse(localStorage.getItem("user"));

    if (user) {
      const newData = await httpRequest(
        "GET",
        "/user/info/" + user?._id,
        {},
        { "x-access-token": user?.token }
      ).then(({ data }) => {
        const values = Object.keys(data.data.user);

        values.map((item) => {
          formik.setFieldValue(item, data.data.user[item]);
        });
      });
    }
    await fetch("https://iran-locations-api.ir/api/v1/fa/states").then(
      async (res) => {
        const data = await res.json();
        setStates(data);
      }
    );
  }

  useEffect(() => {
    getData();
  }, []);

  const VALUE_SIGN_VALIDATION = () => {
    return Yup.object({
      name: Yup.string().required("الزامی است"),
      phone: Yup.string().required("الزامی است"),
      address: Yup.string().required("الزامی است"),
      state: Yup.string().required("الزامی است"),
      city: Yup.string().required("الزامی است"),
    });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      address: "",
      state: "",
      city: "",
      postalCode: "",
      allPrice: total,
      products: [],
      userId: "",
    },
    validationSchema: VALUE_SIGN_VALIDATION,
    onSubmit: async (values) => {
      if (!checked) {
        return toast.error("با شرایط و مقررات سایت موافقت نشده");
      }
      setloading(true);
      const user = JSON.parse(localStorage.getItem("user"));
      values.userId = user?._id;
      cart?.map((e) => {
        values.products.push({
          productId: e.id,
          count: e.count,
          price: e?.special_price ? e?.special_price : e?.price,
        });
      });
      httpRequest("POST", "/order", values, {}).then(async (e) => {
        if (e?.status == 201) {
          localStorage.setItem("amount", total);
          localStorage.setItem("orderId", e.data.data._id);
          await paymentRequest(total, e.data.data._id);
        } else {
          const message = await e.json();
          toast.error(message?.message);
          setloading(false);
        }
      });
    },
  });

  async function getCitis() {
    await fetch(
      "https://iran-locations-api.ir/api/v1/fa/cities?state=" +
        formik?.values?.state
    ).then(async (res) => {
      const data = await res.json();
      if (formik?.values?.state) {
        setCities(data[0].cities);
      } else {
        setCities(data);
      }
    });
  }
  useEffect(() => {
    if (!formik.values.city) {
      getCitis();
    }
  }, [formik.values.state]);
  return (
    <form
      onSubmit={formik.handleSubmit}
      dir="rtl"
      class="mt-10 bg-gray-50 px-4 pt-8 lg:mt-0"
    >
      <p class="text-xl font-medium">اطلاعات خرید</p>
      <p class="text-gray-400">اطلاعات خود را برای سفارش کامل کنید</p>

      <div class="">
        <div className="grid md:grid-cols-2 gap-2">
          <span>
            <label for="email" class="mt-4 mb-2 block text-sm font-medium">
              نام و نام خانوادگی*
            </label>
            <div class="relative">
              <input
                type="text"
                id="name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                class="w-full rounded-md border border-gray-200 px-3 py-3 pl-6 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            {formik.errors.name && formik.touched.name && (
              <small className="text-danger" style={{ color: "red" }}>
                {formik.errors.name}
              </small>
            )}
          </span>
          <span>
            <label for="email" class="mt-4 mb-2 block text-sm font-medium">
              شماره تماس*
            </label>
            <div class="relative">
              <input
                type="text"
                id="phone"
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                class="w-full rounded-md border border-gray-200 px-3 py-3 pl-6 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            {formik.errors.phone && formik.touched.phone && (
              <small className="text-danger" style={{ color: "red" }}>
                {formik.errors.phone}
              </small>
            )}
          </span>

          <span>
            <label for="email" class="mt-4 mb-2 block text-sm font-medium">
              کد پستی
            </label>
            <div class="relative">
              <input
                type="text"
                id="postalCode"
                name="postalCode"
                value={formik.values.postalCode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                class="w-full rounded-md border border-gray-200 px-3 py-3 pl-6 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
          </span>

          <span>
            <label for="email" class="mt-4 mb-2 block text-sm font-medium">
              استان*
            </label>
            <div class="relative">
              <select
                id="state"
                name="state"
                value={formik.values.state}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                class="w-full rounded-md border border-gray-200 px-3 py-3 pl-6 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
              >
                <option></option>
                {states?.map((e) => (
                  <option>{e.name}</option>
                ))}
              </select>
            </div>
            {formik.errors.state && formik.touched.state && (
              <small className="text-danger" style={{ color: "red" }}>
                {formik.errors.state}
              </small>
            )}
          </span>
          <span>
            <label for="email" class="mt-4 mb-2 block text-sm font-medium">
              شهر*
            </label>
            <div class="relative">
              <select
                id="city"
                name="city"
                value={formik.values.city}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={formik.values.state === ""}
                class="w-full rounded-md border border-gray-200 px-3 py-3 pl-6 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
              >
                <option></option>
                {cities?.map((e) => (
                  <option>{e.name}</option>
                ))}
              </select>
            </div>
            {formik.errors.city && formik.touched.city && (
              <small className="text-danger" style={{ color: "red" }}>
                {formik.errors.city}
              </small>
            )}
          </span>
          <span className="col-span-full">
            <label for="email" class="mt-4 mb-2 block text-sm font-medium">
              آدرس*
            </label>
            <div class="relative">
              <textarea
                type="text"
                id="address"
                name="address"
                value={formik.values.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                class="w-full rounded-md border border-gray-200 px-3 py-3 pl-6 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            {formik.errors.address && formik.touched.address && (
              <small className="text-danger" style={{ color: "red" }}>
                {formik.errors.address}
              </small>
            )}
          </span>

          <span className="col-span-full">
            <p>
              از داده های شخصی شما برای پردازش سفارش شما ، پشتیبانی از تجربه شما
              در سراسر این وب سایت و برای اهداف دیگری که در سیاست حفظ حریم خصوصی
              ما شرح داده شده استفاده خواهد شد.
            </p>
            <span className="flex gap-2 mt-4 items-start">
              <p>من شرایط و مقررات سایت را خوانده ام و آن را می پذیرم</p>
              <input
                onClick={() => setchecked(!checked)}
                checked={checked}
                type="checkbox"
                className="mt-2"
              />
            </span>
          </span>
        </div>
      </div>
      {loading ? (
        <div role="status" className="flex justify-center my-3">
          <svg
            aria-hidden="true"
            class={`w-12 h-12 text-gray-200 animate-spin fill-[#000]`}
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span class="sr-only">Loading...</span>
        </div>
      ) : (
        <button
          type="submit"
          class="mt-4 mb-8 w-full rounded-md bg-gray-900 px-6 py-3 font-medium text-white"
        >
          ارسال سفارش
        </button>
      )}
    </form>
  );
};

export default CheckoutForm;
